/* for all pages should include this */
body {
    font-smoothing: subpixel-antialiased !important;
    -webkit-font-smoothing: subpixel-antialiased !important;
    -moz-osx-font-smoothing: auto !important;
    text-shadow: 0 0 1em transparent, 1px 1px 1.2px rgba(0, 0, 0, 0.004);
    /*text-rendering: optimizeLegibility;*/
    -webkit-overflow-scrolling: touch;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    letter-spacing: 0.025em;
}
:focus, .focus {
    outline: none !important;
}
::-moz-focus-inner {
    border: 0 !important;
}

/* manual fix for bootstrap issue 14040, there is an unnecessary padding-right on modal open */
body.modal-open {
    overflow-y: auto;
    padding-right: 0 !important;
}
